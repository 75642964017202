import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Spacer | Code'}
			subHeader={'Spacer'}
			description={'Spacer'}
			keywords={['Spacer Code']}
			embeddedVideo={''}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/d193c74c419c65626980ddb076d1e599/raw/b08db1269a7ae30866dd2c29e618a1ef1b2095b1/gistfile1.txt'}		

			></CodeSnippet>
		);
	}
}

export default View;
